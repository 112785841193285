// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cleaning-solutions-js": () => import("./../../../src/pages/cleaning-solutions.js" /* webpackChunkName: "component---src-pages-cleaning-solutions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-floor-series-page-js": () => import("./../../../src/pages/floor-series-page.js" /* webpackChunkName: "component---src-pages-floor-series-page-js" */),
  "component---src-pages-images-js": () => import("./../../../src/pages/images.js" /* webpackChunkName: "component---src-pages-images-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-k-10-compact-js": () => import("./../../../src/pages/k10-compact.js" /* webpackChunkName: "component---src-pages-k-10-compact-js" */),
  "component---src-pages-k-10-standard-js": () => import("./../../../src/pages/k10-standard.js" /* webpackChunkName: "component---src-pages-k-10-standard-js" */),
  "component---src-pages-k-12-series-js": () => import("./../../../src/pages/k12-series.js" /* webpackChunkName: "component---src-pages-k-12-series-js" */),
  "component---src-pages-k-16-series-js": () => import("./../../../src/pages/k16-series.js" /* webpackChunkName: "component---src-pages-k-16-series-js" */),
  "component---src-pages-k-19-series-js": () => import("./../../../src/pages/k19-series.js" /* webpackChunkName: "component---src-pages-k-19-series-js" */),
  "component---src-pages-k-25-series-js": () => import("./../../../src/pages/k25-series.js" /* webpackChunkName: "component---src-pages-k-25-series-js" */),
  "component---src-pages-l-series-js": () => import("./../../../src/pages/l-series.js" /* webpackChunkName: "component---src-pages-l-series-js" */),
  "component---src-pages-m-series-js": () => import("./../../../src/pages/m-series.js" /* webpackChunkName: "component---src-pages-m-series-js" */),
  "component---src-pages-orbitz-10-nano-js": () => import("./../../../src/pages/orbitz-10-NANO.js" /* webpackChunkName: "component---src-pages-orbitz-10-nano-js" */),
  "component---src-pages-orbitz-20-floor-machine-js": () => import("./../../../src/pages/orbitz-20-floor-machine.js" /* webpackChunkName: "component---src-pages-orbitz-20-floor-machine-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-s-17-d-js": () => import("./../../../src/pages/product-s17d.js" /* webpackChunkName: "component---src-pages-product-s-17-d-js" */),
  "component---src-pages-products-2-js": () => import("./../../../src/pages/products-2.js" /* webpackChunkName: "component---src-pages-products-2-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-rider-series-js": () => import("./../../../src/pages/rider-series.js" /* webpackChunkName: "component---src-pages-rider-series-js" */),
  "component---src-pages-s-series-js": () => import("./../../../src/pages/s-series.js" /* webpackChunkName: "component---src-pages-s-series-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-warranty-js": () => import("./../../../src/pages/warranty.js" /* webpackChunkName: "component---src-pages-warranty-js" */),
  "component---src-pages-xl-series-js": () => import("./../../../src/pages/xl-series.js" /* webpackChunkName: "component---src-pages-xl-series-js" */)
}

